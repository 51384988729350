html{
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
}

body {
    cursor: default;
}


// SECTIONS + CONTAINERS
.container {
    width: 90%;
    margin: 0rem auto 0rem;
}

.section {
    // overflow: hidden;
    height: 100vh;
    scroll-snap-align: start;

    &--angie {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0rem 2rem;
        scroll-snap-align: start;

        @media(min-width:768px) {
            margin: 0rem 15rem;
        }

        @media(min-width:1200px) {
            display: flex;
            flex-direction: row;
            scroll-snap-align: start;
            margin: 0rem 10rem;
        }
    }
}

.projet {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    scroll-snap-align: start;


    @media(min-width:768px) {
        display: flex;
        justify-content: center;
    }

    @media(min-width:900px) {
        display: flex;
        flex-direction: column;
    }

    @media(min-width:1024px) {
        flex-direction: row;
        align-items: center;
    }


    @media(min-width:1200px) {
        justify-content: space-around;
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 0rem 4rem;
        scroll-snap-align: start;
    }
}

.head {
    height: 100vh;
    scroll-snap-align: start;

    @media(min-width:1200px) {
        scroll-snap-align: start;
    }
}


// TITRES 

.title {

    &--minuscule {
        position: fixed;
        width: max-content;
        right: -1rem;
        top: 50%;
        transform: rotate(-90deg);
    }

    &--big {
        margin: 10rem 0rem;
        position: absolute;
        z-index: 6;
        display: flex;
        flex-direction: column;
        pointer-events: none;

        @media(min-width:768px) {
            width: 90vw;
            margin: 12rem 0rem;
        }

        @media(min-width:900px) {
            width: 90vw;
            margin: 13rem 0rem;
        }

        @media(min-width:1024px) {
            height: 100vh;
            justify-content: center;
        }

        @media(min-width:1200px) {
            display: flex;
            height: 90vh;
            width: 90vw;
            margin: 0;
            align-items: flex-end;
            justify-content: flex-end;
            pointer-events: none;
            flex-direction: column;
        }
    }

    &--second {
        margin: 4rem 0rem;

        @media(min-width:1200px) {
            align-items: center;
            display: flex;
            width: 50vw;
            margin: 0;
        }
    }

    &--thirdbis {
        display: flex;
        align-items: center;
        grid-gap: 10px;
        padding-bottom: 2.2rem;
        width: fit-content;

        @media(min-width:1200px) {
            padding: 0;
            display: inline-flex;

            &:hover {
                transform: translateX(2px);
                transition: 0.5s;
                color: gray;
            }
        }
    }

    &--secondbis {
        margin: 3rem 0rem 1rem;
        line-height: 1;
    }
}

// IMAGES 
.img {

    object-fit: cover;
    object-position: top;

    &--angie {
        position: absolute;
        bottom: -90px;
        left: 0;
        width: 290px;
        height: auto;
        z-index: 5;

        @media(min-width:360px) {
            bottom: 0;
        }

        @media(min-width:375px) {
            bottom: -90px;
        }

        @media(min-width:768px) {
            width: 65vw;
        }

        @media(min-width:900px) {
            width: 60vw;
        }

        @media(min-width:1024px) {
            width: 50vw;
        }

        @media(min-width:1200px) {
            height: 100vh;
            width: 40vw;
            top: 0;
        }
    }

    &--projets {
        width: 90%;
        cursor: pointer;

        @media(min-width:768px) {
            height: 50vh;
            width: 80%;
        }

        @media(min-width:1024px) {
            width: 100%;
            object-fit: contain;
        }

        @media(min-width:1200px) {
            display: flex;
            flex-direction: column;
            align-self: flex-end;
            width: 50vw;
            height: 80vh;

            &:hover {
                transform: scale(1.1);
                transition: 1.5s ease-in-out;
            }
        }
    }


    .container_img {
        overflow: hidden;

        @media(min-width:1200px) {
            display: flex;
        }
    }



}

// LIENS
// LISTES + NAVIGATIONS
.liste {
    margin: 0;
    padding: 0;
    list-style: square inside;
    display: flex;
    order: 1;
    grid-gap: 22px;

    &--footer,
    &--footer a {
        list-style: none;
        color: black;
        text-decoration: none;
    }
}


.nav {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--header {
        position: fixed;
        z-index: 200;
        top: 22px;
        width: 90vw;
    }

    &--footer {
        width: 90vw;
    }
}


// FOOTERS

.footer {
    position: fixed;
    bottom: 22px;
    width: calc(100vw - 1rem);
}

.darkmode {
    width: 20px;
    height: 20px;
    background-color: black;
    border-radius: 50%;
    order: 1;
    cursor: pointer;

    @media(min-width:900px) {
        width: 40px;
        height: 40px;
    }

    @media(min-width:1024px) {
        width: 20px;
        height: 20px;
    }

    @media(min-width:1200px) {
        width: 20px;
        height: 20px;
    }
}

._footer {
    list-style: none;
}

.hidden {
    position: relative;
    border: none;
    display: inline-block;
    overflow: hidden;
    display: block;
    width: 0px;
    height: 0px;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

//AUTRES

.trait {
    width: 30px;
    height: 2px;
    background-color: black;

    @media(min-width:1200px) {

        &:hover {
            transform: translateX(2px);
            transition: 0.5s;
            background-color: gray;
        }
    }
}

.globale {

    @media(min-width:1200px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

a {
    cursor: pointer;
    color: black;
    text-decoration: none;
    display: contents;

    @media(min-width:1200px) {

        &:hover {
            transform: translateX(20px);
            transition: 0.5s;
            color: gray;
        }
    }

}


body.dark {
    background: #000;
    color: #fff;
    transition: 0.9s ease-in-out;

    .darkmode {
        background-color: white;
        transition: 0.9s ease-in-out;
    }
}

.dark .trait {
    background-color: white;
    transition: 0.9s ease-in-out;
}

.dark a {
    color: #fff;
    transition: 0.9s ease-in-out;
}


.pages_transition {
    transition: all 0.7s linear;
}


@media(min-width:1200px) {

    .container_img {
        overflow: hidden;
        display: flex;
        height: 80vh;
        width: 100%;
        object-fit: contain;
        align-self: flex-end;
    }
}

// .projet{
//     scroll-snap-type: y mandatory;
//     scroll-behavior: smooth;
//     overflow-y: scroll;
// }

// .section{
//     scroll-snap-type: y mandatory;
//     scroll-behavior: smooth;
//     overflow-y: scroll;
// }