:root {
    font-size: 10px;
}

body {
    font-family: 'Sora', sans-serif;
    font-size: 1.5rem;
    font-weight: 300;
    margin: 0;
    padding: 0;
    line-height: 1.5;

    @media(min-width:900px) {
        font-size: 1.8rem;
    }

    @media(min-width:1200px) {
        font-size: 1.5rem;
    }
}

.title {
    margin: 0;
    padding: 0;

    &--big {
        font-size: 6rem;
        font-family: 'Sora';
        font-weight: 500;
        line-height: 1;
        text-align: right;
        text-transform: uppercase;

        @media(min-width:768px) {
            font-size: 9rem;
        }

        @media(min-width:900px) {
            font-size: 12rem;
        }

        @media(min-width:1200px) {
            font-size: 15vw;
            line-height: 0.9;
            font-family: 'Sora';
            font-weight: 500;
            text-transform: uppercase;
        }
    }

    &--second {
        font-size: 3rem;
        font-family: 'Italiana', serif;
        font-weight: 400;
        line-height: 0.95;

        @media(min-width:768px) {
            font-size: 6rem;
        }

        @media(min-width:900px) {
            font-size: 6rem;
        }

        @media(min-width:1200px) {
            font-size: 8rem;
        }

    }

    &--secondbis {
        font-size: 3.5rem;
        font-family: 'Sora', sans-serif;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: -5px;

        @media(min-width:768px) {
            font-size: 6rem;
        }

        @media(min-width:900px) {
            font-size: 7rem;
        }

        @media(min-width:1024px) {
            font-size: 4vw;
        }

        @media(min-width:1200px) {
            font-size: 4.5vw;
        }

    }

    &--third {
        font-size: 1.8rem;
        font-family: 'Sora', sans-serif;
        font-weight: 600;

        @media(min-width:900px) {
            font-size: 3rem;
        }

        @media(min-width:1200px) {
            font-size: 2.2rem;
        }
    }

    &--thirdbis {
        font-size: 1.2rem;
        font-family: 'Sora', sans-serif;
        font-weight: 300;
        text-transform: uppercase;
        display: flex;
        align-items: center;
    }

    &--minuscule {
        font-size: 8px;
        font-weight: 200;
        text-transform: uppercase;

        @media(min-width:768px) {
            font-size: 1.2rem;
        }

        @media(min-width:900px) {
            font-size: 1.5rem;
        }

        @media(min-width:1024px) {
            font-size: 1.2rem;
        }

        @media(min-width:1200px) {
            font-size: 1rem;
        }
    }
}

.elmt_liste {
    font-size: 1.2rem;
    font-weight: 200;
    text-transform: uppercase;

    @media(min-width:900px) {
        font-size: 2.2rem;
    }

    @media(min-width:1024px) {
        font-size: 1.6rem;
    }

    @media(min-width:1200px) {
        font-size: 1.6rem;
    }
}

.taille {
    text-transform: uppercase;

    @media(min-width:1200px) {
        font-size: 1.4rem;

        &:hover {
            transform: translateX(2px);
            transition: 0.5s;
            color: gray;
            // font-weight: 400;
        }
    }
}

.logo {
    font-size: 4rem;
    font-family: 'Italiana', serif;
    font-weight: 400;
    text-transform: uppercase;

    @media(min-width:900px) {
        font-size: 4.5rem;
    }
}

.infos {
    @media(min-width:1200px) {
        width: 305px;
    }
}

.detail {

    padding: 0px 0px 15px 0px;
    font-family: 'Italiana', serif;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 1.8rem;

    @media(min-width:1200px) {
        padding: 0px 13px 40px 0px;
    }
}